<template>
  <span>
    <v-overlay :value="isLoading" absolute opacity="0.7">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="600px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title class="mb-0 pb-0">
          <span class="text-h5">Create a new user</span>
        </v-card-title>
        <v-card-text>
          <div class="text-h6 text-subtitle-2 mt-0 pt-0">Provides login access</div>
          <v-form ref="form" @submit.prevent="doCreate">
            <v-container fluid>
              <v-text-field v-model="user.firstName" :rules="inputs.rules.simplyRequired" label="First name" placeholder="Johnny" outlined></v-text-field>
              <v-text-field v-model="user.lastName" :rules="inputs.rules.simplyRequired" label="Last name" placeholder="Appleseed" outlined></v-text-field>
              <v-text-field v-model="user.email" :rules="inputs.rules.emailRequired" label="Email" placeholder="john.Appleseed@sea2seascuba.com" outlined></v-text-field>
              <v-text-field v-model="user.phoneNumber" :rules="inputs.rules.phoneNumberRequired" v-mask="['(###) ###-####']" label="Phone number" placeholder="(602) 123-0000" outlined></v-text-field>
              <v-row>
                <v-col md="8">
                  <v-text-field id="password" v-model="user.password" :rules="inputs.rules.passwordRequired" label="Password" outlined readonly></v-text-field>
                </v-col>
                <v-col>
                  <v-btn fab color="primary" @click="doGenerate" class="ml-2">
                    <font-awesome-icon :icon="['fas', 'gears']" fixed-width></font-awesome-icon>
                  </v-btn>
                  <v-btn fab color="primary" @click="doCopy" class="ml-4">
                    <font-awesome-icon :icon="['fas', 'copy']" fixed-width></font-awesome-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-autocomplete v-model="user.roleKey" :items="roles" :rules="inputs.rules.simplyRequired" outlined item-text="name" item-value="key" label="Role"></v-autocomplete>
              <v-row>
                <v-col>
                  <v-btn @click="doClose">
                    <font-awesome-icon :icon="['fas', 'times']" fixed-width></font-awesome-icon>
                    <span class="ml-2">Cancel</span>
                  </v-btn>
                </v-col>
                <v-col align="right">
                  <v-btn color="primary" type="submit">
                    <span class="mr-2">Create</span>
                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'admin-control-add-user',
  data: () => ({
    isLoading: false,
    isOpen: false,
    roles: [],
    user: {
      firstName: String(),
      lastName: String(),
      email: String(),
      phoneNumber: String(),
      password: null,
      roleKey: String()
    }
  }),
  methods: {
    doOpen () {
      this.isLoading = true

      this.$services.role.doGetAll().then(roles => {
        this.isOpen = true
        this.roles = roles
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
      this.doInit()
      this.$emit('doLoad')
    },
    doInit () {
      this.$refs.form.resetValidation()
      this.user = {
        firstName: String(),
        lastName: String(),
        email: String(),
        phoneNumber: String(),
        password: null,
        roleKey: String()
      }
    },
    doCreate () {
      if (this.$refs.form.validate()) {
        this.$services.user.doCreate(this.user.firstName, this.user.lastName, this.user.email, this.user.phoneNumber, this.user.password, this.user.roleKey).then(user => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        })
      }
    },
    doGenerate () {
      const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const passwordLength = 12
      let password = ''
      for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }

      this.user.password = password
    },
    doCopy () {
      if (this.user.password === null) {
        this.doGenerate()
      }

      this.$nextTick(() => {
        const copyText = document.getElementById('password')
        copyText.select()
        document.execCommand('copy')
      })
    }
  }
}
</script>

<style scoped>

</style>
