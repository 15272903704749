<template>
  <span>
    <v-overlay :value="isLoading" absolute opacity="0.7">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="600px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">Update user</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="doUpdate" ref="form">
            <v-text-field v-model="user.firstName" :rules="inputs.rules.simplyRequired" label="First name" placeholder="Johnny" outlined></v-text-field>
            <v-text-field v-model="user.lastName" :rules="inputs.rules.simplyRequired" label="Last name" placeholder="Appleseed" outlined></v-text-field>
            <v-text-field v-model="user.email" :rules="inputs.rules.emailRequired" label="Email" placeholder="john.Appleseed@sea2sea.com" outlined></v-text-field>
            <v-text-field v-model="user.phoneNumber" :rules="inputs.rules.phoneNumberRequired" v-mask="['(###) ###-####']" label="Phone number" placeholder="(602) 123-0000" outlined></v-text-field>
            <v-autocomplete v-model="user.roleKey" :items="roles" :rules="inputs.rules.simplyRequired" outlined item-text="name" item-value="key" label="Role"></v-autocomplete>
            <v-row>
              <v-col>
                <v-btn @click="doClose">
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width></font-awesome-icon>
                  <span class="ml-2">Cancel</span>
                </v-btn>
              </v-col>
              <v-col align="right">
                <v-btn color="error" class="mr-2" @click="doDestroy">
                  <span>Delete</span>
                </v-btn>
                <v-btn color="primary" type="submit">
                  <span>Update</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'admin-control-update-user',
  data: () => ({
    isLoading: false,
    isOpen: false,
    isValidated: null,
    roles: [],
    user: {
      id: null,
      firstName: String(),
      lastName: String(),
      email: String(),
      phoneNumber: String(),
      password: null,
      roleKey: String()
    }
  }),
  methods: {
    doOpen (id) {
      this.isLoading = true

      new Promise((resolve, reject) => {
        this.$services.role.doGetAll().then(roles => {
          this.roles = roles
          this.$services.user.doReadById(id).then(user => {
            this.user = user
            resolve()
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      }).then(_ => {
        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doGenerate () {
      const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const passwordLength = 12
      let password = ''
      for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }

      this.user.password = password
      this.$forceUpdate()
    },
    doCopy () {
      if (this.user.password === null) {
        console.log('Hello')
        this.doGenerate()
      }

      this.$nextTick(() => {
        const copyText = document.getElementById('updatePassword')
        copyText.select()
        document.execCommand('copy')
      })
    },
    doClear () {
      this.user.password = null
      this.$forceUpdate()
    },
    doInit () {
      this.$refs.form.resetValidation()
      this.user = {
        firstName: String(),
        lastName: String(),
        email: String(),
        phoneNumber: String(),
        password: null,
        roleKey: String()
      }
    },
    doDestroy () {
      this.$root.$emit('showError', 'Account deletion is currently unavailable, please contact support')
    },
    doClose () {
      this.doInit()
      this.isOpen = false
    },
    doUpdate () {
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated) {
        this.$root.$emit('showError', 'Account update is not currently available, please contact support')
      }
    }
  }
}
</script>

<style scoped>

</style>
