<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="width: 200px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline"> {{ systemControl.controlValue ? 'Disable' : 'Enable' }}</span>
        </span>
        <span>
          <span class="ml-2">
            {{ systemControl.title }} ?
          </span>
        </span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doClose"
        >
          Cancel
        </v-btn>
        <v-btn
          :color="systemControl.controlValue ? 'red' : 'success'"
          text
          @click="doToggleControl"
        >
          {{ systemControl.controlValue ? 'Disable' : 'Enable' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'adminToggleSystemControl',
  data: () => ({
    isLoading: false,
    isOpen: false,
    systemControl: {
      title: String(),
      controlKey: String(),
      controlValue: false
    }
  }),
  methods: {
    doOpen (controlKey) {
      this.systemControl.controlKey = controlKey
      this.isLoading = true

      this.$services.systemControl.doGetByKey(controlKey).then(control => {
        this.systemControl = control

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doToggleControl () {
      this.isOpen = false

      this.isLoading = true

      this.$services.systemControl.doUpdateControl(this.systemControl.controlKey, {
        controlValue: !this.systemControl.controlValue
      }).then().catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
        this.doClose()
      })
    },
    doClose () {
      this.$emit('doLoad')
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
